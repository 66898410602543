import axios from 'axios'
import { matchesOrContained } from './helpers'

const elements = {}

export default {
  init: function () {
    elements.search = document.querySelector('[name="Search"]')
    elements.autocomplete = document.getElementById('autocomplete')

    if (elements.autocomplete) {
      elements.autocomplete.addEventListener('click', handleAutocompleteClick)

      document.addEventListener('click', event => {
        const isClickOutside = matchesOrContained(
          '#autocomplete, [name="Search"]',
          event.target
        )
        elements.autocomplete.hidden = !isClickOutside
      })
    }

    if (elements.search) {
      elements.search.autocomplete = 'off'

      let lastValue = ''
      let currIndex = -1

      elements.search.addEventListener('keyup', async event => {
        if (elements.autocomplete) {
          const autocompleteItems = elements.autocomplete.children

          elements.autocomplete.hidden = false

          if (autocompleteItems[currIndex]) {
            autocompleteItems[currIndex]
              .querySelector('button')
              .classList.remove('active')
          }

          if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault()

            const totalItems = autocompleteItems.length - 1

            if (event.key === 'ArrowUp') {
              currIndex = currIndex ? currIndex - 1 : totalItems
            }
            if (event.key === 'ArrowDown') {
              currIndex = currIndex !== totalItems ? currIndex + 1 : 0
            }

            if (autocompleteItems[currIndex]) {
              const element =
                autocompleteItems[currIndex].querySelector('button')

              element.classList.add('active')
              elements.search.value = element.textContent
            }

            return
          }
        }

        const currentValue = event.target.value.trim()

        // Bail early if taget value didn`t change
        if (currentValue === lastValue) {
          return
        }

        lastValue = currentValue

        const results = currentValue
          ? await axios.get('/wp-json/wp/v2/search', {
              params: {
                search: currentValue,
                per_page: 5,
              },
            })
          : { data: [] }

        if (elements.autocomplete) {
          // Reset current index of active autocomplete item
          currIndex = -1

          const next = results.data.map(item => item.title)
          updateAutocompleteResults(next)
        }
      })

      // if (elements.search && elements.autocomplete) {
      //   elements.search.addEventListener('focusout', event => {
      //     setTimeout(() => {
      //       elements.autocomplete.hidden = true
      //     }, 100)
      //   })
      // }
    }
  },
}

function handleAutocompleteClick(event) {
  if (event.target.tagName === 'BUTTON') {
    elements.search.value = event.target.textContent
    elements.search.form.submit()
  }
}

function updateAutocompleteResults(items) {
  Array.from(elements.autocomplete.children).forEach((child, i) => {
    const element = child.querySelector('button')
    if (items[i]) {
      //console.log('replace', i, [element.textContent, items[i]])
      element.textContent = items[i]
      delete items[i]
    } else {
      //console.log('remove', i, element.textContent)
      elements.autocomplete.removeChild(child)
    }
  })

  items.forEach(value => {
    const li = createAutocompleteResult(value)
    elements.autocomplete.appendChild(li)
  })
}

function createAutocompleteResult(value) {
  //console.log('create', elements.autocomplete.children.length, value)

  const button = document.createElement('button')
  button.classList.add('dropdown-item', 'text-truncate')
  button.textContent = value

  const li = document.createElement('li')
  li.appendChild(button)

  return li
}
