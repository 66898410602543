import { CountUp } from 'countup.js'

const elements = {}
const instances = new WeakMap()

export default {
  init: function () {
    elements.counter = document.querySelectorAll('[data-count]')

    elements.counter.forEach(element => {
      const counter = new CountUp(element, element.textContent, {
        duration: 3,
        prefix: element.dataset.prefix || '',
        suffix: element.dataset.suffix || '',
      })

      instances.set(element, counter)
    })
  },
  instances,
}
