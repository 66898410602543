import { throttle } from './helpers'

function getElementViewRatio(element) {
  const rect = element.getBoundingClientRect()
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight
  const windowWidth = window.innerWidth || document.documentElement.clientWidth

  // Calculate the visible part of the element in the viewport
  const verticalVisible =
    Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0)
  const horizontalVisible =
    Math.min(rect.right, windowWidth) - Math.max(rect.left, 0)

  // Ensure positive values for visibility
  const visibleArea =
    Math.max(verticalVisible, 0) * Math.max(horizontalVisible, 0)
  const totalArea = rect.width * rect.height

  // Ratio of the visible area to the total area of the element
  const viewRatio = (visibleArea / totalArea).toFixed(2)

  // Ensure the ratio is between 0 and 1
  return Math.min(Math.max(viewRatio, 0), 1)
}

const handleScroll = (target, sections) =>
  throttle(() => {
    let maxRatio = 0 // To store the maximum ratio found
    let mostVisibleElement = null // To store the element with the highest visibility

    sections.forEach(section => {
      const viewRatio = getElementViewRatio(section) // Get current element's ratio
      if (viewRatio > maxRatio) {
        // If it's higher than what we have so far,
        maxRatio = viewRatio // update the maximum ratio
        mostVisibleElement = section // and store the current element.
      }
    })

    if (mostVisibleElement) {
      let sectionId = mostVisibleElement.getAttribute('id')
      let navLink = target.querySelector(`[href="#${sectionId}"]`)

      if (navLink) {
        // Remove active class from all navigation links
        target.querySelectorAll('[href^="#"]').forEach(link => {
          link.classList.remove('active')
        })
        // Add active class to the corresponding navigation link
        navLink.classList.add('active')
      }
    }
  }, 200)

export default {
  init: () => {
    const dataSpyList = document.querySelectorAll('[data-bs-spy="scroll"]')
    dataSpyList.forEach(dataSpyEl => {
      if (!dataSpyEl.dataset.bsTarget) return

      const target = document.querySelector(dataSpyEl.dataset.bsTarget)
      const sections = dataSpyEl.querySelectorAll('[id]')

      document.addEventListener('scroll', handleScroll(target, sections))
      handleScroll(target, sections)()
    })
  },
  destroy: () => {
    document.removeEventListener('scroll', handleScroll)
  },
}
