import Modal from 'bootstrap/js/dist/modal'
import axios from 'axios'

import { getPostId } from './helpers'

const elements = {}
let modal

async function showModal(id) {
  if (elements.modal && modal) {
    const res = await axios.get(myAjax.ajaxUrl, {
      params: {
        action: 'get_modal_post',
        id,
      },
    })

    elements.modal.querySelector('.modal-title').textContent = res.data.title
    elements.modal.querySelector('.modal-body-content').innerHTML =
      res.data.body

    modal.show()
  }
}

export default {
  init: () => {
    elements.modal = document.getElementById('modal')

    if (elements.modal) {
      modal = new Modal(elements.modal)
    }

    document
      .querySelectorAll('.article.type-people, .article.type-portfolio')
      .forEach(element => {
        element.addEventListener('click', event => {
          event.preventDefault()

          // Prevent flickity drag from opening the modal
          const carouselCell = event.target.closest('.carousel-cell')
          if (carouselCell && carouselCell.contains(event.target)) {
            return
          }

          const id = getPostId(element)
          showModal(id)
        })
      })
  },
  show: showModal,
}
