import { gsap, Expo, Power2 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { observe } from './observer'
import carousel from './carousel'
import collapse from './collapse'
import counter from './counter'
import { wrapChars, wrapWords } from './helpers'

gsap.config({ nullTargetWarn: false })
gsap.registerPlugin(ScrollTrigger)

const elements = {}

export default {
  init: () => {
    elements.hero = document.querySelector('.hero')
    elements.menu = document.getElementById('menu')
    elements.section = document.querySelectorAll('.section')
    elements.filterInputs = document.querySelectorAll(
      '.form-control, .form-select'
    )

    menuAnimation()

    // Skip any further animations for search page with results
    if (window.location.search.startsWith('?Search=')) {
      return
    }

    const heroTimeline = heroAnimation()

    // Run about page animations and skip others
    if (document.body.classList.contains('focus-and-commitment')) {
      aboutPageAnimation(heroTimeline)
      return
    }

    elements.section.forEach(sectionAnimation)
    carousel.instances.forEach(flickityAnimation)
    collapse.instances.forEach(collapseAnimation)
  },
}

function heroAnimation() {
  if (elements.hero) {
    // Adding a class here and animating in CSS because of better performance
    elements.hero.classList.add('animate-in')
  }

  const timeline = gsap.timeline({
    delay: elements.hero ? 0.5 : 0, // Hardcoded delay for hero CSS animation
  })

  timeline.from(
    '.site-nav',
    {
      opacity: 0,
      translateY: -100,
      scale: 1.1,
    },
    elements.hero ? '-=0.3' : 0
  )

  timeline.from('.hero-media-overlay', {
    opacity: 0,
    translateY: 100,
  })

  return timeline
}

function menuAnimation() {
  menu.querySelectorAll('.nav-link').forEach(element => {
    element.classList.add('animate')
  })

  const timeline = gsap.timeline({
    paused: true,
  })

  timeline.from(menu.querySelectorAll('.nav-link span'), {
    stagger: 0.1,
    opacity: 0,
    translateY: 100,
    delay: 0.25,
  })

  menu.addEventListener('show.bs.modal', () => timeline.play(0.01))
}

function collapseAnimation(collapse) {
  const element = collapse._element
  const timeline = gsap.timeline({ paused: true })

  sectionTitleAnimation(element.querySelector('.section-title'), timeline)

  timeline.from(
    element.querySelectorAll(
      ':scope > .row > .col > h4, :scope > .row > .col > p'
    ),
    {
      opacity: 0,
      translateY: 50,
    },
    '<'
  )

  timeline.from(element.querySelectorAll(':scope > .row .row > .col'), {
    opacity: 0,
    stagger: 0.5,
  })

  element.addEventListener('show.bs.collapse', () => {
    timeline.play(0.01)
  })

  element.addEventListener('shown.bs.collapse', ScrollTrigger.refresh)
  element.addEventListener('hidden.bs.collapse', ScrollTrigger.refresh)
}

function flickityAnimation(flickity) {
  let timeout
  let didRun = false
  observe(flickity.element, () => ({ isIntersecting }) => {
    if (isIntersecting && !didRun) {
      timeout = setTimeout(() => {
        const i = flickity.selectedIndex
        flickity.selectCell(!i ? 2 : i + 1)
        didRun = true
      }, 4000)
    } else {
      clearTimeout(timeout)
    }
  })

  flickity.cells.forEach(cell => {
    const element = cell.element

    observe(element, () => ({ isIntersecting }) => {
      if (isIntersecting) {
        if (
          !element.classList.contains('in-viewport') &&
          element.classList.contains('animated-in')
        ) {
          gsap.from(element.querySelector('.article'), {
            opacity: 0,
            scale: 0.8,
            translateX: 100,
          })
        }

        element.classList.add('in-viewport')
      }
    })
  })
}

function sectionAnimation(element) {
  const sectionTitle = element.querySelector('.section-title')
  const sectionSubline = element.querySelector('.section-subline')
  const sectionCols = element.querySelectorAll(
    ':scope > .container > .row > .col:not(.shuffle-item)'
  )
  const sectionColImages = element.querySelectorAll(
    ':scope > .container > .row > .col:not(.shuffle-item) img'
  )
  const nestedCols = element.querySelectorAll(
    ':scope > .container > .row > .col:not(.shuffle-item) .col'
  )
  const carouselCols = element.querySelectorAll('.carousel-cell .article')
  const shuffleCols = element.querySelectorAll('.shuffle .col > *')
  const sectionMore = element.querySelector('.more-link')

  const isSmallHero = elements.hero?.classList.contains('hero-sm') ?? false

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: element,
      start: `top ${isSmallHero ? 'bottom' : '70%'}`,
    },
  })

  if (element.querySelector('[data-shuffle]')) {
    elements.filterInputs.forEach(element => {
      element.addEventListener('focusin', () => timeline.play())
    })
  }

  timeline.from(element, {
    opacity: 0,
  })

  if (sectionCols.length > 1) {
    timeline.from(sectionCols, {
      opacity: 0,
      stagger: 0.5,
    })
  }

  timeline.addLabel('sectionColsReady')

  if (sectionTitle) {
    sectionTitleAnimation(sectionTitle, timeline)
  }

  if (sectionSubline) {
    sectionSublineAnimation(sectionSubline, timeline)
  }

  timeline.addLabel('sectionHeaderReady')

  if (sectionColImages.length > 1) {
    timeline.from(
      sectionColImages,
      {
        opacity: 0,
        stagger: 0.5,
        translateY: 50,
      },
      'sectionColsReady'
    )
  }

  if (nestedCols.length) {
    timeline.from(
      nestedCols,
      {
        opacity: 0,
        scale: 0.8,
        stagger: {
          amount: 1,
          onComplete: function () {
            const count = this.targets()[0].querySelector('[data-count]')
            if (count) {
              counter.instances.get(count).start()
            }
          },
        },
      },
      '-=0.5'
    )
  }

  if (shuffleCols.length) {
    shuffleColsAnimation(shuffleCols, timeline)
  }

  if (carouselCols.length) {
    timeline.from(
      carouselCols,
      {
        opacity: 0,
        scale: 0.8,
        translateX: 100,
        stagger: {
          amount: 1,
          onComplete: function () {
            this.targets()[0].parentNode.classList.add('animated-in')
          },
        },
      },
      '<'
    )
  }

  if (sectionMore) {
    timeline.from(
      sectionMore,
      {
        opacity: 0,
        translateY: 50,
        onComplete: function () {
          const target = this.targets()[0]
          target.style.opacity = ''
          target.style.transform = ''
        },
      },
      'sectionHeaderReady-=1'
    )
  }
}

function sectionTitleAnimation(element, timeline) {
  element.classList.add('animate')

  element.innerHTML = `<span>${wrapWords(
    element.textContent,
    '<span class="text-nowrap">$&</span>'
  )}</span>`

  // Wrap each letter in a span
  element.querySelectorAll('span span').forEach(span => {
    span.innerHTML = wrapChars(span.textContent)
  })

  timeline.from(element, { opacity: 0 })

  timeline.from(
    element.querySelectorAll('span span span'),
    {
      translateY: 25,
      opacity: 0,
      ease: Expo.easeOut,
      stagger: 0.03,
    },
    '<'
  )
}

function sectionSublineAnimation(element, timeline) {
  element.classList.add('animate')
  element.innerHTML = `<span>${element.textContent}</span>`

  timeline.from(
    element.querySelector('span'),
    {
      duration: 1.4,
      translateY: 100,
      ease: Expo.easeOut,
    },
    '<'
  )
}

function shuffleColsAnimation(elements, timeline) {
  timeline.from(
    elements,
    {
      before: function () {
        const element = this.targets()[0]
        element.classList.remove('hover-scale')
      },
      opacity: 0,
      scale: 0.8,
      translateY: 50,
      stagger: {
        amount: 1,
        axis: 'y',
        grid: 'auto',
        onComplete: function () {
          const element = this.targets()[0]
          element.classList.add('animated-in')
          element.classList.add('hover-scale')

          // Hide elements currently not in viewport
          if (!element.classList.contains('in-viewport')) {
            element.style.opacity = 0
          }
        },
      },
    },
    '<'
  )

  // Observe elements and start animation on elements previously not in
  // viewport as soon as they intersect
  elements.forEach(element => {
    observe(element, () => ({ isIntersecting }) => {
      if (!isIntersecting) return

      if (
        !element.classList.contains('in-viewport') &&
        element.classList.contains('animated-in')
      ) {
        gsap.fromTo(
          element,
          { scale: 0.8 },
          { scale: 1, opacity: 1, delay: 0.25 }
        )
      }

      element.classList.add('in-viewport')
    })
  })
}

function aboutPageAnimation(previousTimeline) {
  const image = document.querySelector('main img')

  if (image) {
    image.addEventListener('load', () => {
      image.hidden = false
      gsap.to(image, { opacity: 1 })
    })

    image.srcset = image.dataset.srcset
    image.src = image.dataset.src

    // Create an animation for the fixed image of the about page that is bound
    // to the scroll position of the div that wraps all sections
    gsap
      .timeline({
        ease: Power2.easeOut,
        scrollTrigger: {
          trigger: 'main > div',
          start: 'top top',
          end: 'bottom bottom',
          scrub: 1,
        },
      })
      .from(image, { scale: 2.5 })
  }

  elements.section.forEach((section, i) => {
    const sectionH1 = section.querySelector('.h1')
    const sectionMore = section.querySelector('.more-link')
    const sectionTitle = section.querySelector('.section-title')
    const sectionSubline = section.querySelector('.section-subline')
    const sectionText = section.querySelector('.section-text')

    const isFirst = i === 0
    const isLast = i === elements.section.length - 1

    // Create a timeline for each section that is bound to the scroll position
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        scrub: true,
        markers: false,
        start: isFirst ? 'center center' : isLast ? 'top center' : '25% 75%',
        end: isLast ? 'bottom bottom' : 'bottom 25%',
      },
    })

    const scrollSignifier = section.querySelector('.scroll-signifier-btn')
    if (scrollSignifier) {
      scrollSignifier.addEventListener('click', () => {
        window.scrollTo({
          top: window.innerHeight * 0.875,
          behavior: 'smooth',
        })
      })
    }

    if (sectionH1) {
      // Animate first section headline if it's in viewport
      if (isFirst) {
        if (window.scrollY < section.offsetHeight) {
          sectionTitleAnimation(sectionH1, previousTimeline)

          if (scrollSignifier) {
            previousTimeline.from(
              scrollSignifier,
              {
                opacity: 0,
                translateY: 100,
              },
              '-=0.25'
            )
          }
        } else {
          tl.from(sectionH1, { opacity: 1 })
        }

        tl.to(sectionH1, {
          opacity: 0,
          translateY: -100,
        })

        if (scrollSignifier) {
          tl.to(
            scrollSignifier,
            {
              opacity: 0,
              translateY: -100,
              duration: 0.25,
            },
            '-=1'
          )
        }
      }

      // Reverse animation for last section headline
      if (isLast) {
        tl.from(sectionH1, {
          opacity: 0,
          translateY: 100,
        })
      }
    }

    if (sectionMore) {
      tl.from(
        sectionMore,
        {
          opacity: 0,
          translateY: 100,
        },
        '-=0.35'
      )
    }

    if (sectionTitle) {
      sectionTitleAnimation(sectionTitle, tl)
    }

    if (sectionSubline) {
      sectionSublineAnimation(sectionSubline, tl)
    }

    if (sectionText) {
      tl.from(
        sectionText,
        {
          opacity: 0,
          translateY: 50,
        },
        '0.25'
      )
    }

    if (sectionTitle) {
      tl.to(sectionTitle, { opacity: 0 }, '+=0.5')
    }

    if (sectionSubline) {
      tl.to(
        sectionSubline,
        {
          opacity: 0,
          translateY: -100,
          duration: 1.4,
          ease: Power2.easeIn,
        },
        '>-0.35'
      )
    }

    if (sectionText) {
      tl.to(
        sectionText,
        {
          opacity: 0,
          duration: 1.4,
          ease: Power2.easeIn,
        },
        '<'
      )
    }
  })
}
