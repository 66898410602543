import Flickity from 'flickity'
import 'flickity-fade'
import 'flickity/css/flickity.css'
import 'flickity-fade/flickity-fade.css'

export default {
  init: () => {
    const heroSlider = document.querySelector('.hero-slider')

    if (heroSlider && heroSlider.children.length > 1) {
      new Flickity(heroSlider, {
        fade: true,
        autoPlay: 5000,
        prevNextButtons: false,
      })
    }
  },
}
