let observer

const observed = new WeakMap()

export const init = () => {
  observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        const { callback } = observed.get(entry.target)
        if (callback) callback(entry)
      })
    },
    {
      rootMargin: '0px',
      threshold: 0,
    }
  )
}

export const observe = (element, callback) => {
  if (!observer) init()

  observer.observe(element)
  observed.set(element, {
    callback: callback(element),
  })
}
