// Polyfills
import 'focus-visible'
import 'intersection-observer'

// Local modules
import Cursor from './cursor'
import Global from './global'
import Hero from './hero'
import Collapse from './collapse'
import Modal from './modal'
import Counter from './counter'
import Carousel from './carousel'
import ArchiveFilter from './archive-filter'
import Animations from './animations'
import Autocomplete from './autocomplete'

/**
 * INIT
 *
 * This function initializes all modules used on the site, try to keep it as
 * simple as possible moving any complicated logic into the modules itself.
 */
const INIT = function () {
  /**
   * Initialize all local modules
   */
  Global.init()
  Hero.init()
  Modal.init()
  Counter.init()
  Collapse.init()
  Carousel.init()
  Cursor.init()
  ArchiveFilter.init()
  Animations.init()
  Autocomplete.init()

  /**
   * Expose all modules globally under the `SilverLake` domain for runtime
   * access. Make sure to inititalize all modules before this assignment.
   */
  window.SilverLake = {
    Cursor,
    Global,
    Hero,
    Collapse,
    Modal,
    Carousel,
    ArchiveFilter,
    Animations,
    Counter,
    Autocomplete,
  }

  /**
   * Global resize and scroll handlers for performance reasons.
   */
  window.addEventListener('resize', onResize)
  window.addEventListener('scroll', onScroll)
}

const onResize = function () {
  Global.onResize()
}

const onScroll = function () {
  Global.onScroll()
  ArchiveFilter.onScroll()
}

// Initialize as soon as the DOM content has been loaded
document.addEventListener('DOMContentLoaded', INIT)
