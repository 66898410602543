import Collapse from 'bootstrap/js/dist/collapse'

const elements = {}
const instances = []

export default {
  init: () => {
    elements.collapse = document.querySelectorAll('.collapse')

    elements.collapse.forEach(element => {
      const collapse = new Collapse(element)

      element.addEventListener('show.bs.collapse', () => {
        collapse._triggerArray.forEach(trigger => {
          const node = trigger.childNodes[0]
          node.textContent = node.textContent.replace('Show', 'Hide')
        })
      })

      element.addEventListener('hide.bs.collapse', () => {
        collapse._triggerArray.forEach(trigger => {
          const node = trigger.childNodes[0]
          node.textContent = node.textContent.replace('Hide', 'Show')
        })
      })

      instances.push(collapse)
    })
  },
  instances,
}
