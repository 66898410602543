import Flickity from 'flickity'
import 'flickity/css/flickity.css'

import Modal from './modal'
import { getPostId } from './helpers'

const elements = {}
const instances = []

export default {
  init: () => {
    elements.articleCarousel = document.querySelectorAll('.carousel')

    elements.articleCarousel.forEach(element => {
      const container = element.querySelector('.container')

      if (container) {
        const flkty = new Flickity(container, {
          contain: true,
          prevNextButtons: false,
          pageDots: false,
          imagesLoaded: true,
          on: {
            ready: function () {
              this.slider.classList.add('row')
            },
            // Open modal
            staticClick: async (event, pointer, cellElement) => {
              const id = getPostId(cellElement.querySelector('.article'))
              Modal.show(id)
            },
          },
        })

        flkty.element.addEventListener('click', event => {
          if (event.currentTarget !== flkty.selectedElement) {
            event.preventDefault()
          }
        })

        instances.push(flkty)
      }
    })
  },
  instances,
}
