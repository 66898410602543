import Shuffle from 'shufflejs'
import axios from 'axios'

import { debounce } from './helpers'

const elements = {}
const filter = {}
let shuffleInstance,
  currentPage = 1,
  isMaxPage = false,
  searchTerm = ''

const filterAll = () => {
  // Apply the filter to the instance
  shuffleInstance.filter(itemPassesFilters)

  if (searchTerm) {
    shuffleInstance.filter((element, shuffle) => {
      // Ignore elements that don't match if there is a filter applied
      if (shuffle.group !== Shuffle.ALL_ITEMS) {
        // Get the item's groups
        const groups = JSON.parse(element.getAttribute('data-groups'))
        const isElementInCurrentGroup = groups.includes(shuffle.group)
        // Only search elements in the current group
        if (!isElementInCurrentGroup) {
          return false
        }
      }

      const filterElement = element.querySelector('[data-filter]')
      return filterElement.textContent.toLowerCase().trim().includes(searchTerm)
    })
  }
}

const itemPassesFilters = element => {
  let filterValues = Object.values(filter).filter(Boolean)

  // If there is no filter applied, show all elements
  if (filterValues.length === 0) {
    return true
  }

  // Shuffle expects a string if there is only one filter applied
  if (filterValues.length === 1) {
    filterValues = filterValues[0]
  }

  // Parse the element's data-groups attribute
  const groups = JSON.parse(element.getAttribute('data-groups'))

  // If `groups` is an array we just use the default filter provided by Shuffle
  if (Array.isArray(groups)) {
    return shuffleInstance._doesPassFilter(filterValues, element)
  }

  // Since we used JSON.parse above and `groups` is not an array it must be an
  // object - a `groups` object is a map of strategy names to investment values
  const filterInvestment = filter['filter-investment']
  const filterStrategy = filter['filter-strategy']

  // If a investment filter is applied
  if (filterInvestment) {
    if (filterStrategy) {
      // Show element if the selected strategy has the selected investment strategy
      return groups[filterStrategy] === filterInvestment
    }

    if (filterInvestment === 'current-investments') {
      // Show element if it has the `current-investments` strategy
      return Object.values(groups).includes('current-investments')
    }

    // Show element if all strategies have the selected investment strategy
    return Object.values(groups).every(value => value === filterInvestment)
  }

  // Else if only the strategy filter is applied
  if (filterStrategy) {
    // Show element if it has the selected strategy
    return Object.keys(groups).includes(filterStrategy)
  }

  console.info('There is no filter logic to handle the provided data', groups)
  return true
}

const handleFilterInput = ({ target }) => {
  searchTerm = target.value.toLowerCase()

  filterAll()
}

const handleFilterSelect = ({ target }) => {
  // Save the selected filter value in the filter object
  filter[target.name] = target.value

  // If the `role` filter changes
  if (target.name === 'filter-role') {
    // Show/hide role information based on the selected role
    elements.rolesInfo.forEach(element => {
      element.hidden =
        element.getAttribute('data-group') !==
        (target.value || Shuffle.ALL_ITEMS)
    })
  }

  // If the `strategy` filter changes
  if (target.name === 'filter-strategy') {
    if (elements.ltcNoticeElement) {
      // Show/hide ltcNoticeElement based on the selected role
      elements.ltcNoticeElement.hidden = target.value !== 'silver-lake-ltc'
    }
  }

  filterAll()
}

const handleSubmit = event => {
  event.preventDefault()
  document.activeElement.blur()
}

const getSearchResults = async (params = {}) => {
  const searchTerm = elements.searchInput.value
  const searchYear = elements.searchSelect.value

  const res = await axios.get(myAjax.ajaxUrl, {
    params: {
      postType: myAjax.postType,
      action: 'load_search_results',
      query: searchTerm,
      year: searchYear,
      ...params,
    },
  })

  if (res.data) {
    isMaxPage = false

    const incoming = document.createRange().createContextualFragment(res.data)
    return Array.from(incoming.children)
  } else {
    isMaxPage = true
    return []
  }
}

const handleSearchResults = async () => {
  isMaxPage = false
  currentPage = 1

  const curElements = Array.from(elements.postContainer.children)
  const newElements = await getSearchResults()

  const getKey = el => el?.dataset?.key
  const sorter = {}

  // Go through current elements and check if they can be found in new search results
  curElements.forEach(curElement => {
    const found = newElements.find(
      newElement => getKey(newElement) === getKey(curElement)
    )
    if (!found) {
      // Element has not been found, remove from instance (also removes it from container)
      shuffleInstance.remove([curElement])
    } else {
      // Element has been found, save new index for sorting
      sorter[getKey(found)] = newElements.indexOf(found)
    }
  })

  // Sort current elements by previously saved search results index
  shuffleInstance.sort({
    compare: (a, b) => sorter[getKey(a.element)] - sorter[getKey(b.element)],
  })

  // Go through new search results and check if they can be found in current elements
  newElements.forEach((newElement, i) => {
    const found = curElements.find(
      curElement => getKey(curElement) === getKey(newElement)
    )
    if (!found) {
      // Elements has not been found, insert into container at the right index
      elements.postContainer.insertBefore(
        newElement,
        elements.postContainer.children[i]
      )
      // Register new element to the instance
      shuffleInstance.add([newElement])
    }
  })

  // Shuffle needs an update if curElements was empty
  if (!curElements.length) {
    shuffleInstance.update()
  }

  // Show/hide the noResults container
  if (elements.noResultsElement) {
    elements.noResultsElement.hidden = elements.postContainer.children.length
  }
}

const handleLoadMore = async () => {
  // Do not load more when search select has no value
  if (elements.searchSelect && elements.searchSelect.value === '') {
    return
  }

  // Load more search results by passing an increased paging
  const newElements = await getSearchResults({ page: ++currentPage })

  // Append results to container and register them to the instance
  elements.postContainer.append(...newElements)

  if (shuffleInstance) {
    shuffleInstance.add(newElements)
  }
}

let lastMaxHeight = 0
let layoutHappened = false

const setMaxHeight = () => {
  const items = shuffleInstance?.items.filter(item => item.isVisible)

  // Bail if there are no visible items
  if (!items || items.length === 0) {
    return
  }

  const maxHeight = items.reduce((acc, item) => {
    item.element.style.height = ''

    const height = item.element.offsetHeight
    if (height > acc) {
      acc = height
    }
    return acc
  }, 0)

  items.forEach(item => {
    item.element.style.height = `${maxHeight}px`
  })

  if (maxHeight !== lastMaxHeight) {
    // Only update if the maxHeight has changed
    shuffleInstance.layout()
    lastMaxHeight = maxHeight
  } else if (!layoutHappened) {
    // After the maxHeight has been set, layout needs to be called once again
    shuffleInstance.layout()
    layoutHappened = true
  } else {
    // Reset for next call
    layoutHappened = false
  }
}

const init = () => {
  elements.postContainer = document.querySelector('.posts')
  elements.filterInput = document.querySelector('input[name="filter"]')
  elements.filterSelect = document.querySelectorAll('select[name^="filter-"]')
  elements.rolesInfo = document.querySelectorAll('[data-group]')
  elements.searchInput = document.querySelector('input[name="search"]')
  elements.searchSelect = document.querySelector('select[name="search-year"]')
  elements.pagination = document.querySelector('.pagination')
  elements.inputReset = document.querySelectorAll('[data-reset]')
  elements.ltcNoticeElement = document.getElementById('ltc-notice')
  elements.noResultsElement = document.getElementById('no-results')

  if (elements.postContainer) {
    if (elements.postContainer.hasAttribute('data-shuffle')) {
      // Get additional data from the container
      const shuffleData = elements.postContainer.dataset.shuffle
      const shuffleOptions = (shuffleData && JSON.parse(shuffleData)) || {}

      shuffleInstance = new Shuffle(elements.postContainer, {
        itemSelector: '.col',
        filterMode: Shuffle.FilterMode.ALL,
      })

      if (shuffleOptions.sameHeight) {
        shuffleInstance.on(
          Shuffle.EventType.LAYOUT,
          debounce(() => setMaxHeight(), 100)
        )
        setMaxHeight()
      }

      // Show/hide the noResults container
      if (elements.noResultsElement) {
        elements.noResultsElement.hidden =
          elements.postContainer.children.length
      }
    }

    if (elements.filterInput) {
      elements.filterInput.form.addEventListener('submit', handleSubmit)
      elements.filterInput.addEventListener('keyup', handleFilterInput)
    }

    elements.filterSelect.forEach(select => {
      handleFilterSelect({ target: select })
      select.addEventListener('change', handleFilterSelect)
    })

    if (elements.searchInput) {
      elements.searchInput.form.addEventListener('submit', handleSubmit)
      elements.searchInput.addEventListener(
        'keyup',
        debounce(handleSearchResults, 500)
      )
    }

    if (elements.searchSelect) {
      elements.searchSelect.addEventListener('change', handleSearchResults)
    }

    if (elements.pagination && elements.searchInput && elements.searchSelect) {
      elements.pagination.hidden = true
    }

    elements.inputReset.forEach(element => {
      const target = document.querySelector(`[name=${element.dataset.reset}]`)

      if (target) {
        function resetInput() {
          target.value = ''

          if (target === elements.filterInput) {
            handleFilterInput({ target })
          }
          if (target === elements.searchInput) {
            handleSearchResults()
          }

          const event = new Event('keyup')
          target.dispatchEvent(event)
        }

        target.addEventListener('keyup', () => {
          if (target.value) {
            element.classList.add('icon-close-primary')
            element.classList.remove('icon-search')

            element.addEventListener('click', resetInput)
          } else {
            element.classList.remove('icon-close-primary')
            element.classList.add('icon-search')

            element.removeEventListener('click', resetInput)
          }
        })
      }
    })
  }
}

const onScroll = debounce(() => {
  if (elements.pagination || elements.searchInput || elements.searchSelect) {
    if (
      !isMaxPage &&
      Math.round(window.scrollY + window.innerHeight) >=
        document.body.clientHeight
    ) {
      handleLoadMore()
    }
  }
}, 500)

export default {
  init,
  onScroll,
}
