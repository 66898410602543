import * as NiceSelect from 'nice-select2'
import Scrollspy from './scrollspy'

// Import Scss shared variables
import variables from '../styles/exports.scss'

const exports = {}
let lastScrollTop = 0

// Convert dash seperated variable names into nested object structure
for (const variableName in variables) {
  const parts = variableName.split('-')
  exports[parts[0]] = exports[parts[0]] || {}
  exports[parts[0]][parts[1]] = variables[variableName]
}

const elements = {}

function handleMobileChange(event) {
  if (event.matches) {
    elements.menuPrimary.hidden = true
    elements.menuSecondary.prepend(...elements.menuPrimary.childNodes)

    Scrollspy.destroy()
  } else {
    elements.menuPrimary.hidden = false
    elements.menuPrimary.append(...elements.menuPrimaryChildren)

    Scrollspy.init()
  }
}

let lastWindowHeight = 0

function setHeroHeight() {
  if (
    elements.hero &&
    elements.hero.matches(':not(.hero-sm):not(.hero-xs)') &&
    lastWindowHeight !== window.outerHeight
  ) {
    elements.hero.style.setProperty('--hero-height', `${window.innerHeight}px`)
    lastWindowHeight = window.outerHeight
  }
}

export default {
  init: () => {
    elements.siteNav = document.querySelector('.site-nav')
    elements.menuPrimary = document.getElementById('menu-primary')
    elements.menuPrimaryChildren = Array.from(elements.menuPrimary.childNodes)
    elements.menuSecondary = document.getElementById('menu-secondary')
    elements.selects = document.querySelectorAll('select')
    elements.hero = document.querySelector('.hero')

    setHeroHeight()

    elements.selects.forEach(select => {
      NiceSelect.bind(select)
    })

    const mediaQuery = window.matchMedia(
      `(max-width: ${exports.breakpoints.md})`
    )

    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handleMobileChange)
    } else {
      mediaQuery.addListener(handleMobileChange)
    }
    handleMobileChange(mediaQuery)
  },
  onScroll: () => {
    const isSinglePeople = document.body.classList.contains('single-people')
    const isSinglePortfolio =
      document.body.classList.contains('single-portfolio')

    if (isSinglePeople || isSinglePortfolio) return

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const scrollSpeed = Math.abs(scrollTop - lastScrollTop)

    const windowHeight = window.innerHeight

    const isScrollingDown = scrollTop > lastScrollTop
    const isFocusAndCommitmentPage = document.body.classList.contains(
      'focus-and-commitment'
    )
    const isEndOfPage = scrollTop >= document.body.offsetHeight - windowHeight

    const heroHeight =
      (elements.hero?.offsetHeight ?? windowHeight / 2) -
      elements.siteNav.offsetHeight

    lastScrollTop = scrollTop

    if (isScrollingDown && scrollTop > heroHeight) {
      elements.siteNav.classList.add('is-hidden')
    }

    if (
      (!isScrollingDown && scrollSpeed >= 10) ||
      (isFocusAndCommitmentPage && isEndOfPage)
    ) {
      elements.siteNav.classList.remove('is-hidden')
    }

    elements.siteNav.classList.toggle('is-fixed', scrollTop > heroHeight)
  },
  onResize: () => {
    setHeroHeight()
  },
}
