export function throttle(func, waitTime) {
  let timeout = null
  let previous = 0

  const later = () => {
    previous = Date.now()
    timeout = null
    func()
  }

  return function () {
    const now = Date.now()
    const remaining = waitTime - (now - previous)
    if (remaining <= 0 || remaining > waitTime) {
      if (timeout) clearTimeout(timeout)
      later()
    } else if (!timeout) {
      timeout = setTimeout(later, remaining)
    }
  }
}

export function debounce(func, waitTime, immediate) {
  let timeout
  return function () {
    const context = this,
      args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, waitTime)
    if (callNow) func.apply(context, args)
  }
}

export function isElementInViewport(element) {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * Get post ID by given element (id)
 * @param {HTMLElement} element
 * @returns
 */
export function getPostId(element) {
  return element.id.substring('post-'.length)
}

/**
 * Wraps a string around each character/letter
 *
 * @param {string} str The string to transform
 * @param {string} tmpl Template that gets interpolated
 * @returns {string} The given input as splitted by chars/letters
 */
export function wrapChars(str, tmpl) {
  return str.replace(/\S/g, tmpl || '<span>$&</span>')
}

/**
 * Wraps a string around each word
 *
 * @param {string} str The string to transform
 * @param {string} tmpl Template that gets interpolated
 * @returns {string} The given input splitted by words
 */
export function wrapWords(str, tmpl) {
  return str.replace(/\S+/g, tmpl || '<span>$&</span>')
}

/**
 * Returns whether the element matches the given selector
 * or is contained by an element matching the selector
 *
 * @param {string} selector
 * @param {HTMLElement} element
 * @returns {boolean}
 */
export function matchesOrContained(selector, element) {
  return Boolean(
    element.matches(selector) || element.closest(selector)?.contains(element)
  )
}
